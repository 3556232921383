<template>
  <div>
    <div class="header-top">
      <img class="tp-img" src="../../assets/images/teamwork/partner-ban.png" />
      <div class="tp-title">企业风采</div>
      <div class="tp-ic"></div>
      <div class="tp-body"><div class="underline"></div></div>
      <div class="p-pagination">
        <el-button style="width: 180px;height: 50px;font-size: 22px" @click="cut1">公司荣誉</el-button>
        <el-button style="width: 180px;height: 50px;font-size: 22px" @click="cut2">员工风采</el-button>
      </div>
      <div class="tp-content">
        <div class="tp-content" v-if="one">

          <div  class="middletwo">
            <img
              class="middle-img"
              src="../../assets/images/teamwork/test.png"
            />
          </div>
          <div  class="middletwo1">
            <!-- <el-carousel
              indicator-position="outside"
              arrow="never"
              :interval="2500"
              style="height: 350px"
              ref="carousel"
              @mouseenter.native="delHandleMouseEnter"
            >
              <el-carousel-item  style="height: 350px">
                <img class="middle-img" src="../../assets/images/teamwork/certificate-5.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 350px">
                <img class="middle-img" src="../../assets/images/project/519-5.jpg" />
              </el-carousel-item>
            </el-carousel> -->
           
            <!-- <el-carousel
              indicator-position="outside"
              arrow="never"
              :interval="2500"
              style="height: 350px"
              ref="carousel"
              @mouseenter.native="delHandleMouseEnter"
            > -->
              <!-- <el-carousel-item  style="height: 350px">
                <img class="middle-img" src="../../assets/images/teamwork/certificate-6.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 350px">
                <img class="middle-img" src="../../assets/images/project/519-6.jpg" />
              </el-carousel-item>
            </el-carousel>  -->
            <swper-teams :speed="2" :direction="'left'"></swper-teams>       
          </div>
          <div  class="middle">
            <el-carousel
              indicator-position="outside"
              arrow="never"
              :interval="2500"
              style="height: 410px"
              ref="carousel"
              @mouseenter.native="delHandleMouseEnter"
            >
              <el-carousel-item  style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-1.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-1-2.png" />
              </el-carousel-item>
            </el-carousel>
          
          </div>
          <div  class="middle">
            <el-carousel
              indicator-position="outside"
              arrow="never"
              :interval="2500"
              style="height: 410px"
              ref="carousel"
              @mouseenter.native="delHandleMouseEnter"
            >
              <el-carousel-item  style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/certificate-7.png" />
              </el-carousel-item>
            </el-carousel>

          </div>
          <div  class="middle">
            <el-carousel
              indicator-position="outside"
              arrow="never"
              :interval="2500"
              style="height: 410px"
              ref="carousel"
              @mouseenter.native="delHandleMouseEnter"
            >
              <el-carousel-item  style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-3.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-4.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-5.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-6.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-7.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-8.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-9.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-10.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-11.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-13.png" />
              </el-carousel-item>
              <el-carousel-item style="height: 410px">
                <img class="middle-img" src="../../assets/images/teamwork/honor-14.png" />
              </el-carousel-item>
            </el-carousel>

          </div>
        </div>

<!--        第二页-->

        <div class="tp-content1" v-if="two">
          <swper-team :speed="2" :direction="'left'"></swper-team>        
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import swperTeam from '../../components/swperTeam.vue';
import swperTeams from '../../components/swperTeams.vue';
export default {
  components: { swperTeam,swperTeams },
  data() {
    return {
      one: true,
      two: false,
      TheStyle: {
        background: "#8cc6ee",
        color: "#ffffff",
      },
      TheStyletwo: {
        background: "#ffffff",
        color: "#2b292f",
      },
    };
  },
  mounted () {
    this.$refs.carousel.handleMouseEnter = () => { };
  },
  methods: {
    delHandleMouseEnter() {
      this.$refs.carousel.handleMouseEnter = () => { };
    },
    cut1() {
      this.$data.TheStyle.backgroundColor = " #8cc6ee";
      this.$data.TheStyle.color = "#ffffff";
      this.$data.TheStyletwo.backgroundColor = "#ffffff";
      this.$data.TheStyletwo.color = "#2b292f";
      this.one = true;
      this.two = false;
    },
    cut2() {
      this.$data.TheStyle.backgroundColor = "#ffffff";
      this.$data.TheStyle.color = "#2b292f";
      this.$data.TheStyletwo.backgroundColor = " #8cc6ee";
      this.$data.TheStyletwo.color = "#ffffff";
      this.one = false;
      this.two = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.middle {
  background:  #8cc6ee;
  width: 376px;
  height: 410px;
  margin-left: 30px;
  margin-top: 30px;
  overflow: hidden;

  .middle-img {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease 0.1s;
  }

  .middle-title {
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 35px;
  }
}
.middletwo {
  background:  #8cc6ee;
  width: 376px;
  height: 300px;
  margin-left: 30px;
  margin-top: 30px;
  overflow: hidden;
  .middle-img {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease 0.1s;
  }

}
.middletwo1 {
  background:  #8cc6ee;
  width: 784px;
  height: 300px;
  margin-left: 30px;
  margin-top: 30px;
  overflow: hidden;
  .middle-img {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease 0.1s;
  }

}
.middles {
  background:  #8cc6ee;
  width: 500px;
  height: 395px;
  margin-left: 80px;
  margin-top: 30px;
  overflow: hidden;

  .middle-imgs {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease 0.1s;
  }
  .middle-imgs:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
  .middle-title {
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 35px;
  }
}

.p-pagination {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.p-pagination a {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 0 10px;
  margin-right: 8px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
}
.header-top {
  min-width: 1250px;
  height: 100%;
  margin: auto;
  .tp-title {
    text-align: center;
    margin-top: 35px;
    color: #1f1f1f;
    font-size: 31px;
  }
  .tp-ic {
    width: 40px;
    height: 4px;
    margin: 20px auto;
    background:  #8cc6ee;
  }
  .tp-content {
    width: 1250px;
    // height: 800px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px auto;
  }
  .tp-content1 {
    width: 1250px;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
  }
}
.underline {
  width: 100%;
  height: 18px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/zigongsi/i-title.png");
}
.tp-body {
  width: 560px;
  margin: auto;
}
.tp-img {
  height: 380px;
  width: 100%;
}
.tp-body-cn {
  margin: auto;
  width: 560px;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.recruit-title {
  color: #666666;
  line-height: 30px;
}
.recruit-content {
  color: #444444;
  font-size: 15px;
  line-height: 30px;
}
.content {
  font-size: 15px;
  line-height: 30px;
  color: #444;
}
.content-title {
  margin-top: 50px;
  margin-bottom: 15px;
  color: #000;
  line-height: 30px;
  font-size: 25px;
}
</style>
